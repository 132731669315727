.cw-floating-button-animation {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 45%) 0 4px 12px 0;
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    position: fixed;
    width: 64px;
    z-index: 40;
    bottom: calc(90px + var(--app-bottom-height, var(--app-safe-area-bottom, 0px)));
    right: 7%;
    transition: all 100ms ease-in-out;

    .floating-button {
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(151, 6, 11);
        border-radius: 50%;
        display: flex;
        height: 50px;
        -webkit-box-pack: center;
        justify-content: center;
        width: 50px;

        span {
            color: #fff !important;
            font-size: 24px !important;
        }
    }
  &-enter {
    opacity: 0;
    transform: translateY(30px);
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.4s ease, opacity 0.4s ease;
  }
}

.chayns-app .cw-floating-button-animation {
    bottom: calc(30px + var(--app-bottom-height, var(--app-safe-area-bottom, 0px)));
}
